.ErrorScreen {
  align-items: center;
  background-attachment: fixed;
  background-image: url(./forest.jpg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 3em 2em;
}

.ErrorScreen > * {
  flex: 100%;
}

.ErrorScreen__Header {
  color: white;
}

.ErrorScreen__Button {
  border-color: white !important;
  color: white !important;
  width: 90vw !important;
}

.ErrorScreen__Button:active,
.ErrorScreen__Button:focus,
.ErrorScreen__Button:hover {
  background: white !important;
  color: #27718c !important;
}


.ErrorScreen__StatusCode {
  color: white;
  font-family: montserrat-semibold;
  font-size: 150px;
  font-weight: bolder;
}

@media screen and (min-width: 576px) {
  .ErrorScreen__Button {
    width: 70vw !important;
  }
}

@media screen and (min-width: 786px) {
  .ErrorScreen__Button {
    width: 40vw !important;
  }
}

@media screen and (min-width: 992px) {
  .ErrorScreen__Button {
    width: 400px !important;
  }
}

.ErrorScreen__StatusCode__C {
  margin: 0 12px;
  margin-bottom: -4px;
}

.ErrorScreen__Message {
  color: white;
  white-space: pre-wrap;
}
