.Loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Loading > * {
  margin: 20px;
}
