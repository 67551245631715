.TaxAccountantModal__Close {
  text-align: right;
}

.TaxAccountantModal__Status {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.TaxAccountantModal__Status--error {
  color: #fa5400;
}

.TaxAccountantModal__Status--success {
  color: #84b922;
}

.TaxAccountantModal__Status__Icon {
  margin: 0 8px;
}

.TaxAccountantModal__Close__Icon {
  cursor: pointer;
}

.TaxAccountantModal__HeaderRow {
  align-items: center;
}
