* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#root > header {
  flex-shrink: 0;
}

.App__Container {
  flex-grow: 1;
  padding-bottom: 30px;
  padding-top: 30px;
}

.Menu__Heading {
  font-family: 'montserrat-semibold';
  font-size: 18px;
}

.Menu__Link,
.Menu__Link:active,
.Menu__Link:focus,
.Menu__Link:hover {
  text-transform: none;
}

@font-face {
  font-family: 'montserrat-light';
  src: url("https://cdn.compeon.de/layouts/fonts/montserrat-light-webfont.woff2") format("woff2");
  src: url("https://cdn.compeon.de/layouts/fonts/montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-semibold';
  src: url("https://cdn.compeon.de/layouts/fonts/montserrat-semibold-webfont.woff2") format("woff2");
  src: url("https://cdn.compeon.de/layouts/fonts/montserrat-semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body,
input,
select,
textarea {
  color: #62676d;
  font-family: montserrat-light;
  font-size: 16px;
  line-height: normal;
}

button {
  font-family: montserrat-semibold;
}

/* ===========
 * Headings
 * ===========
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
  font-family: montserrat-semibold;
  font-weight: 600
}

h1, .h--1 {
  font-size: 32px;
  letter-spacing: -2.3px;
  line-height: 40px;
  margin-bottom: 20px;
}

h2 {
  font-size: 25px;
  letter-spacing: -0.7px;
  margin-bottom: 35px;
}

h3 {
  font-size: 23px;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
}

h4 {
  font-size: 20px;
  letter-spacing: -0.3px;
}

h5, h6 {
  font-size: 16px;
}

@media screen and (min-width: 480px) {
  h1 {
    font-size: 41px;
    line-height: 50px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 26px
  }
}

/* ===========
 * Paragraphs
 * ===========
 */

p {
  font-size: 16px;
  font-weight: 100;
  line-height: 24px;
  padding: 25px 0;
}

p.p--tiny {
  font-size: 12px;
  font-weight: 100;
  line-height: 18px;
}

/* ===========
 * Links
 * ===========
 */

a,
a:hover,
a:visited,
a:active {
  color: #27718c;
}

a,
a:visited,
a:active {
  text-decoration: none;
  text-transform: uppercase;
}

a:hover {
  text-decoration: underline;
}
