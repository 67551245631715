.TaxAccountantModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(1, 57, 76, 0.9);
}

.TaxAccountantModal {
  position: absolute;
  top: 40px;
  left: 10px;
  right: 10px;
  bottom: 40px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  --webkit-overflow-scrolling: touch;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

@media (min-width: 768px) {
  .TaxAccountantModal {
    left: 5vw;
    right: 5vw;
  }
}

@media (min-width: 992px) {
  .TaxAccountantModal {
    left: 10vw;
    right: 10vw;
  }
}

@media (min-width: 1200px) {
  .TaxAccountantModal {
    left: 15vw;
    right: 15vw;
  }
}

.TaxAccountantModal__Body {
  overflow-y: hidden;
}
